(function () {
  const canvas = document.getElementById('bloom');
  const canvasContext = canvas.getContext('2d');
  const parent = canvas.parentNode;
  // canvas isn't responsive, so we have to resize with JS
  const canvasWidth = (canvas.width = parent.offsetWidth);
  const canvasHeight = (canvas.height = parent.offsetWidth * 0.56);

  const audioContext = new (window.AudioContext || window.webkitAudioContext)();

  // removes distortion when many notes are played at once
  const compressor = audioContext.createDynamicsCompressor();
  compressor.threshold.value = -80;
  compressor.knee.value = 40;
  compressor.ratio.value = 12;
  compressor.reduction.value = -30;
  compressor.attack.value = 0;
  compressor.release.value = 0.35;
  compressor.connect(audioContext.destination);

  // frequencies of notes in the Western scale
  const frequencyC = 261.63;
  const frequencyD = 293.66;
  const frequencyF = 349.23;
  const frequencyG = 392.0;
  const frequencyA = 440;

  const circles = [];

  canvas.addEventListener('mouseup', event => {
    const circle = {
      x: event.pageX - canvas.offsetLeft,
      y: event.pageY - canvas.offsetTop,
      radius: 1,
      alpha: 1,
      oscillator: audioContext.createOscillator(),
      gainNode: audioContext.createGain(),
    };

    switch (
      false // each 1/6th of the canvas has a different frequency and color
    ) {
      case !(circle.x < canvasWidth / 6):
        circle.color = '#FFC107';
        circle.oscillator.frequency.value = frequencyC;
        break;
      case !(circle.x < (canvasWidth / 6) * 2):
        circle.color = '#FF5722';
        circle.oscillator.frequency.value = frequencyD;
        break;
      case !(circle.x < (canvasWidth / 6) * 3):
        circle.color = '#FF5252';
        circle.oscillator.frequency.value = frequencyF;
        break;
      case !(circle.x < (canvasWidth / 6) * 4):
        circle.color = '#FF9800';
        circle.oscillator.frequency.value = frequencyG;
        break;
      case !(circle.x < (canvasWidth / 6) * 5):
        circle.color = '#8BC34A';
        circle.oscillator.frequency.value = frequencyA;
        break;
      case !(circle.x > (canvasWidth / 6) * 5):
        circle.color = '#CDDC39';
        circle.oscillator.frequency.value = frequencyC * 2;
        break;
    }

    circle.oscillator.type = 'sine';
    circle.oscillator.connect(circle.gainNode);
    circle.gainNode.connect(compressor);
    circle.oscillator.start(0);

    circles.push(circle);
  });

  const updateData = () =>
    (() => {
      const result = [];
      for (let circle of Array.from(circles)) {
        circle.radius++;
        if (circle.alpha > 0.01) {
          circle.alpha -= 0.005;
          circle.gainNode.gain.value -= 0.005;
        } else {
          circle.alpha = 0;
          circle.gainNode.gain.value = 0;
        }
        if (circle.radius > 400) {
          circle.radius = 1;
          circle.alpha = 1;
          result.push((circle.gainNode.gain.value = 1));
        } else {
          result.push(undefined);
        }
      }
    })();

  const paintCanvas = () => {
    canvasContext.clearRect(0, 0, canvasWidth, canvasHeight);
    return (() => {
      const result = [];
      for (let circle of Array.from(circles)) {
        canvasContext.globalAlpha = circle.alpha;
        canvasContext.fillStyle = circle.color;
        canvasContext.beginPath();
        canvasContext.arc(
          circle.x,
          circle.y,
          circle.radius,
          0,
          Math.PI * 2,
          true,
        );
        canvasContext.closePath();
        result.push(canvasContext.fill());
      }
    })();
  };

  const processFrame = () => {
    updateData();
    paintCanvas();
    // run the code only when the display can use it
    requestAnimationFrame(processFrame);
  };

  // start the recursive loop
  requestAnimationFrame(processFrame);
})();
